// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-developers-js": () => import("./../../../src/templates/developers.js" /* webpackChunkName: "component---src-templates-developers-js" */),
  "component---src-templates-front-page-js": () => import("./../../../src/templates/front_page.js" /* webpackChunkName: "component---src-templates-front-page-js" */),
  "component---src-templates-liity-js": () => import("./../../../src/templates/liity.js" /* webpackChunkName: "component---src-templates-liity-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-redirect-js": () => import("./../../../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */)
}

